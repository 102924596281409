
import { IDate } from "@/types"
import momentImport from "moment"
import { defineComponent, PropType } from "vue"
export default defineComponent({
  name: "DateRangeDescriptionSnippet",
  components: {},
  props: {
    clazz: {
      type: String,
      default: (): string => "pt-1 mb-0",
    },
    item: {
      type: Object as PropType<IDate>,
      default: (): IDate => {
        return {
          id: "",
          from: undefined,
          until: undefined,
          description: undefined,
        }
      },
    },
    isInterval: {
      type: Boolean,
      default: (): boolean => false,
    },
    short: {
      type: Boolean,
      default: (): boolean => false,
    },
    format: {
      type: String,
      default: () => "DD. MMMM",
    },
    wrapperClass: {
      type: String,
      default: (): string => "",
    },
  },
  setup(props) {
    const moment = momentImport
    moment.locale("de")
    const getIntervalValue = (value: number | undefined) => {
      if (value === 1) {
        return "jährlich"
      }

      return `alle ${value} Jahre`
    }
    const getHtmlContent = () => {
      let res = ""

      if (props.item.from && props.item.until) {
        res +=
          moment(props.item.from).format(props.format) +
          " - " +
          moment(props.item.until).format(props.format)
      }

      if (props.item.from && !props.item.until) {
        res += "Ab " + moment(props.item.from).format(props.format)
      }

      if (!props.item.from && props.item.until) {
        res += "Bis " + moment(props.item.until).format(props.format)
      }

      if (res == "" && props.item.internalName) {
        res = props.item.internalName
      }

      if (props.item.interval) {
        const intervalValue = props.isInterval
          ? getIntervalValue(props.item.interval.value)
          : props.item.interval.label
        res += " | " + intervalValue
      }

      if (props.short) {
        return res
      }

      if (props.item.description) {
        res += " | " + props.item.description
      }

      return res
    }

    return {
      moment: moment,
      getHtmlContent: getHtmlContent,
    }
  },
})
