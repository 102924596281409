import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53e8e710"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "aria-labelledby"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "modal-wrapper modal fade modal--sheet",
        id: _ctx.name,
        "aria-labelledby": _ctx.name + 'Label',
        "aria-hidden": "true"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true),
            _renderSlot(_ctx.$slots, "body", {}, undefined, true),
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ])
        ])
      ], 8, _hoisted_1)
    ]),
    _: 3
  }))
}