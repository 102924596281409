import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-0 pb-2"
}
const _hoisted_2 = {
  key: 2,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["card-body p-0", _ctx.wrapperClass])
  }, [
    (_ctx.$slots.heading)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, [
          _renderSlot(_ctx.$slots, "heading")
        ]))
      : _createCommentVNode("", true),
    (_ctx.$slots.htmlContent)
      ? _renderSlot(_ctx.$slots, "htmlContent", { key: 1 })
      : _createCommentVNode("", true),
    (_ctx.$slots.gridContent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "gridContent")
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "defaultContent")
  ], 2))
}