
import { defineComponent } from "vue"
export default defineComponent({
  name: "Modal",
  props: {
    name: {
      type: String,
      default: (): string => "",
      describe: "it will set a name and id on modal",
    },
  },
})
