
import { defineComponent } from "@vue/runtime-core"

export default defineComponent({
  props: {
    wrapperClass: {
      type: String,
      default: (): string => "",
    },
  },
})
