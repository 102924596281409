import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.wrapperClass)
  }, [
    _createElementVNode("p", {
      class: _normalizeClass(_ctx.clazz),
      innerHTML: _ctx.getHtmlContent()
    }, null, 10, _hoisted_1)
  ], 2))
}